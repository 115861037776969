<script setup lang="ts">
import { useField } from "vee-validate";
import { computed, watch } from "vue";
import VueSelect from "vue-select";

const props = defineProps<{
  name: string;
  label?: string;
  placeholder?: string;
  uppercase?: boolean;
  list?: Option[];
  notFoundMessage?: string;
  transform?: (word: string) => string;
}>();
const rand = Math.random().toString();
type Option = { label: string; value: string };

const options = computed(() => props.list?.map(({ label }) => label) || []);

const { handleChange, value, errors, errorMessage } = useField<
  string | undefined
>(props.name, undefined, {});

function onInput(val: string) {
  handleChange(val, errors.value.length > 0);
}

watch(value, (val) => {
  if (val && props.transform && props.transform(val) !== val) {
    value.value = props.transform(val);
  }
  if (!val) {
    // Remove value = null
    value.value = "";
  }
});
</script>

<template>
  <div>
    <label
      v-if="label !== undefined"
      v-sanitize="label"
      :for="rand + name"
      class="mb-2 inline-block"
    />

    <p v-if="errors.length" class="mb-2 ml-1 inline-block text-sm text-red-500">
      {{ errorMessage }}
    </p>

    <VueSelect
      v-model="value"
      :options="options"
      :placeholder="placeholder"
      :class="{ uppercase }"
      @input="onInput(($event.target as HTMLInputElement).value || '')"
    >
      <template v-if="notFoundMessage" #no-options>
        {{
          notFoundMessage
        }}
      </template>
      <template #option="option">
        <span class="max-w-full whitespace-normal">{{
          (option as unknown as Option).label
        }}</span>
      </template>
    </VueSelect>
  </div>
</template>

<style lang="scss">
@import "vue-select/dist/vue-select.css";
.vs__dropdown-toggle {
  --vs-font-size: 0.875rem;
  @apply rounded-none border-0 bg-input px-1 pb-2 pt-[5px];
}
input::placeholder {
  opacity: 0.4;
}

// Hide input because multiline push input outside and create big background
.vs--single .vs__selected + .vs__search {
  display: none;
}

// Show input during dropdown to fix display
.vs--open .vs__selected + .vs__search {
  display: inherit;
}
</style>
