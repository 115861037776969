<script setup lang="ts"></script>

<template>
  <!-- Background -->
  <div class="background min-h-screen w-full bg-black bg-top py-1">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.background {
  background-color: #333;
  background-image: url(../assets/images/bg-grey.jpeg);
}
</style>
