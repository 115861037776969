/**
 * Format select API return
 */
export function formatSelectFromAPI({
  id,
  label,
}: {
  id: string;
  label: string;
}) {
  return {
    label,
    value: id,
  };
}
