<script setup lang="ts">
import GreyLayout from "@/layouts/GreyLayout.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/banner-b2c.png"
      width="1024"
      height="178"
      :alt="t('b2c-img-alt')"
      class="mb-10 w-full"
    >

    <h1 class="mb-6 text-xl text-[#055754] md:text-center md:text-2xl">
      {{ t("landing-velo-confirm") }}
    </h1>
  </GreyLayout>
</template>
