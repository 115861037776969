import { createApp } from "vue";
import App from "./App.vue";
import { sanitize } from "./directives/sanitize";

import { configureSentry } from "./initialize/configureSentry";
import { createI18nConf } from "./initialize/createI18nConf";

import router from "./router";
import "./assets/styles/main.scss";
import "@team-uep/n-cta-loader/dist/style.css";

const app = createApp(App);
app.use(router);
app.use(
  createI18nConf("/api/translations/?format=json", () => app.mount("#app")),
);
app.directive("sanitize", sanitize);

configureSentry({
  app,
  router,
  dsn: "https://5669aecd67a54fb597661548310fd5ce@sentry.numberly.net/648",
});
