<script setup lang="ts">
import GreyLayout from "@/layouts/GreyLayout.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/banner-b2b-1024.png"
      srcset="
        ../assets/images/banner-b2b-512.png  0.5x,
        ../assets/images/banner-b2b-1024.png 1x,
        ../assets/images/banner-b2b-2048.png 2x,
        ../assets/images/banner-b2b-4096.png 4x
      "
      width="1024"
      height="190"
      :alt="t('b2b-img-alt')"
      class="mb-10 w-full"
    >

    <h1 class="mb-6 text-2xl text-[#4d4f53]">
      {{ t("b2b-success-title") }}
    </h1>

    <p v-sanitize="t('b2b-success-intro')" class="mb-6" />
    <p v-sanitize="t('b2b-success-message')" class="mb-6" />
    <p v-sanitize="t('b2b-success-link')" />
  </GreyLayout>
</template>
