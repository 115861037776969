function normalizeHostname(url: string): string {
  if (url.indexOf("http") === 0) {
    return new URL(url).hostname;
  }
  return url;
}

/**
 * Check app running in development by detecting `localhost` or dev URL keyword in the URL.
 *
 * @param {string} [hostname]
 * @returns {boolean}
 */
export function isLocalEnv(hostname = window.location.hostname): boolean {
  const urls = ["localhost", "127.0.0.1", "0.0.0.0"];
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return normalizeHostname(hostname).search(regexp) > -1;
}

/**
 * Check app running in development by detecting `localhost` or dev URL keyword in the URL.
 *
 * @param {string} [hostname]
 * @returns {boolean}
 */
export function isDevEnv(hostname = window.location.hostname): boolean {
  const urls = [".numberly.dev"];
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return normalizeHostname(hostname).search(regexp) > -1;
}

/**
 * Detect app running in staging by detecting `numberly.st` keyword in the URL.
 *
 * @param {string} [hostname]
 * @returns {boolean}
 */
export function isStagingEnv(hostname = window.location.hostname): boolean {
  const urls = [".numberly.st"];
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return hostname.search(regexp) > -1;
}

/**
 * Detect app running in production by checking if not staging or development environment.
 *
 * @param {string} [hostname]
 * @returns {boolean}
 */
export function isProdEnv(hostname = window.location.hostname): boolean {
  return !isDevEnv(hostname) && !isStagingEnv(hostname) && !isLocalEnv();
}
