import type { RailwayOption } from "@/types";

export function extractRailwaysFromList(railways: string[] | undefined, railwayOption: RailwayOption[]) {
  return railways
    ? railways.filter(name =>
      railwayOption.find(railway => railway.value === name),
    )
    : [];
}

/**
 * Format RER or translilien API return
 */
export function formatRailwayFromAPI({
  id,
  external_api_id,
  icon_url,
}: {
  id: string;
  external_api_id: string;
  icon_url: string;
}) {
  return {
    value: id,
    src: icon_url,
    alt: external_api_id,
  };
}
