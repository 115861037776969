<script setup lang="ts">
import InputAutocomplete from "@/components/InputAutocomplete.vue";
import InputCheck from "@/components/InputCheck.vue";
import InputRadio from "@/components/InputRadio.vue";
import InputRailway from "@/components/InputRailway.vue";
import InputText from "@/components/InputText.vue";

import { useApi } from "@/composable/useApi";
import {
  useFrequencyOptions,
  useRerOptions,
  useStationOptions,
  useTransilienOptions,
} from "@/composable/useApiList";
import { useToken } from "@/composable/useToken";
import { removeAccents } from "@/helpers/accents";
import { ApiError, useApiErrorParser } from "@/helpers/apiError";
import { birthdayTest, dateFrToIso, dateIsoToFr } from "@/helpers/date";
import { getEmailRegex } from "@/helpers/email";
import { nameTest } from "@/helpers/name";
import {
  frPhoneToStdPhone,
  getPhoneRegex,
  stdPhoneToFrPhone,
} from "@/helpers/phone";
import { extractRailwaysFromList } from "@/helpers/railway";
import { type ExtractGeneric, RouteNames } from "@/types";
import { NCtaLoader, type ValidStateModel } from "@team-uep/n-cta-loader";
import { toTypedSchema } from "@vee-validate/yup";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";

const apiError = ref("");

const { t } = useI18n();
const route = useRoute();
const api = useApi();
const token = useToken();
const router = useRouter();
const { frequencyOptions } = useFrequencyOptions(apiError);
const { stationOptions, stationTest, getStationId, getStationName }
  = useStationOptions(apiError);
const { rerOptions } = useRerOptions(apiError);
const { transilienOptions } = useTransilienOptions(apiError);
const apiErrorParser = useApiErrorParser();
const displayOptin = ref(true);

function createValidationSchema() {
  const communication = yup
    .boolean()
    .oneOf([true], t("form-communication-error-empty"))
    .required(t("form-communication-error-empty"));

  return yup.object({
    lastName: yup
      .string()
      .test("name", t("form-lastName-error-invalid"), nameTest)
      .required(t("form-lastName-error-empty")),
    firstName: yup
      .string()
      .test("name", t("form-firstName-error-invalid"), nameTest)
      .required(t("form-firstName-error-empty")),
    birthdate: yup
      .string()
      .test("date", t("form-birthdate-error-invalid"), birthdayTest)
      .required(t("form-birthdate-error-empty"))
      .transform(dateFrToIso),
    email: yup
      .string()
      .required(t("form-email-error-empty"))
      .matches(getEmailRegex(), t("form-email-error-invalid")),
    phone: yup
      .string()
      .matches(getPhoneRegex(), {
        excludeEmptyString: true,
        message: t("form-phone-error-invalid"),
      })
      .transform(frPhoneToStdPhone),
    railway: yup
      .array()
      .of(yup.string().required())
      .required(t("form-railway-error-empty"))
      .min(1, t("form-railway-error-empty")),
    startStation: yup
      .string()
      .test("station", t("form-station-error-invalid"), stationTest),
    endStation: yup
      .string()
      .test("station", t("form-station-error-invalid"), stationTest),
    frequency: yup.string().required(t("form-frequency-error-empty")),
    ...(displayOptin.value ? { communication } : {}),
    communication2: yup.boolean(),
    communication3: yup.boolean(),
  });
}

const { handleSubmit, setValues, setErrors } = useForm<
  ExtractGeneric<ReturnType<typeof createValidationSchema>>
>({
  validationSchema: computed(() =>
    toTypedSchema<any, any, any>(createValidationSchema()),
  ),
  initialValues: {
    email: route.query.email ? String(route.query.email) : undefined,
    railway: [],
  },
});

watch(
  token,
  async (tok?: string) => {
    if (tok) {
      try {
        const prefill = await api.getClientB2c(tok);

        // Disable optin if prefill otin is true
        if (prefill.optin) {
          displayOptin.value = false;
        }

        setValues({
          lastName: prefill.last_name,
          firstName: prefill.first_name,
          birthdate: dateIsoToFr(prefill.birth_date),
          email: prefill.email,
          phone: stdPhoneToFrPhone(prefill.mobile_phone),
          railway: [...(prefill.rers || []), ...(prefill.transiliens || [])],
          startStation: getStationName(prefill.departure_station),
          endStation: getStationName(prefill.arrival_station),
          frequency: prefill.frequency,

          // optin is not required if prefill optin is true
          ...(prefill.optin
            ? { communication: undefined }
            : { communication: prefill.optin }),

          communication2: prefill.optin_transilien_access_plus,
          communication3: prefill.optin_transilien_train_velo,
        });
        displayOptin.value = !prefill.optin;

        // Remove optin error
        setErrors({ communication: undefined });
      }
      catch {
        router.push({ name: RouteNames.B2c });
      }
    }
  },
  { immediate: true },
);

const registerLoadingState = ref<ValidStateModel>("default");

const onSubmit = handleSubmit(async (values) => {
  if (registerLoadingState.value !== "default") {
    return;
  }
  registerLoadingState.value = "progress";

  apiError.value = "";
  try {
    await api.submitB2c({
      token: token.value,
      last_name: values.lastName,
      first_name: values.firstName,
      birth_date: values.birthdate,
      email: values.email,
      mobile_phone: values.phone,
      rers: extractRailwaysFromList(values.railway, rerOptions.value),
      transiliens: extractRailwaysFromList(
        values.railway,
        transilienOptions.value,
      ),
      departure_station: getStationId(values.startStation) || undefined,
      arrival_station: getStationId(values.endStation) || undefined,
      frequency: values.frequency,

      // Send optin only if not prefilled with true value
      ...(values.communication === !!values.communication
        ? { optin: values.communication }
        : {}),

      optin_transilien_access_plus: values.communication2,
      optin_transilien_train_velo: values.communication3,
    });

    registerLoadingState.value = "success";
  }
  catch (error: unknown | Error) {
    registerLoadingState.value = "error";
    apiError.value
      = error instanceof Error ? error.message : JSON.stringify(error);

    if (error instanceof ApiError && error.errorMessages) {
      setErrors(apiErrorParser(error.errorMessages));
    }
  }
});

function onApiResponse(actualState: ValidStateModel) {
  if (actualState === "success") {
    return router.push({ name: RouteNames.B2cSuccess });
  }

  if (actualState === "error") {
    registerLoadingState.value = "default";
  }

  return undefined;
}
</script>

<template>
  <form @submit="onSubmit">
    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="lastName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-lastName-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputText
        name="firstName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-firstName-label')"
        :uppercase="true"
        class="flex-1"
      />
    </div>

    <InputText
      name="birthdate"
      mask="##/##/####"
      :input-attr="{
        maxlength: '10',
        placeholder: t('form-birthdate-placeholder'),
      }"
      :label="t('form-birthdate-label')"
      :uppercase="true"
      class="mb-6 md:w-1/2 md:pr-[1.375rem]"
    />

    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="email"
        :input-attr="{ maxlength: '150' }"
        :label="t('form-email-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputText
        name="phone"
        :input-attr="{
          maxlength: '150',
          placeholder: t('form-phone-placeholder'),
          type: 'tel',
        }"
        :label="t('form-phone-label')"
        :uppercase="true"
        mask="0#########"
        class="flex-1"
      />
    </div>

    <InputRailway
      :rer-options="rerOptions"
      :transilien-options="transilienOptions"
      name="railway"
      class="mb-6 max-w-lg"
    >
      {{ t("form-railway-title-b2c") }}
    </InputRailway>

    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputAutocomplete
        name="startStation"
        :placeholder="t('form-startStation-placeholder')"
        :list="stationOptions"
        :label="t('form-startStation-label')"
        :transform="removeAccents"
        :not-found-message="t('form-station-not-found')"
        class="flex-1"
      />

      <InputAutocomplete
        name="endStation"
        :placeholder="t('form-endStation-placeholder')"
        :list="stationOptions"
        :label="t('form-endStation-label')"
        :transform="removeAccents"
        :not-found-message="t('form-station-not-found')"
        class="flex-1"
      />
    </div>

    <InputRadio
      name="frequency"
      :label="t('form-frequency-label')"
      :list="frequencyOptions"
      class="h--title mb-6"
    />

    <template v-if="displayOptin">
      <div class="mb-6">
        <p v-sanitize="t('form-terms-b2c')" class="text-sm" />
      </div>

      <div class="mb-6 flex items-center">
        <img
          src="../assets/images/picto-newsletter.png"
          width="45"
          height="42"
          :alt="t('b2c-picto-newsletter-alt')"
          class="mb-1 mr-2"
        >
        <InputCheck
          name="communication"
          value="true"
          class="optin-check text-sm"
        >
          <span v-sanitize="t('form-communication-label-b2c')" />
        </InputCheck>
      </div>

      <div class="mb-6 flex items-center">
        <img
          src="../assets/images/picto-pmr.png"
          width="45"
          height="42"
          :alt="t('b2c-picto-pmr-alt')"
          class="mb-1 mr-2"
        >
        <InputCheck
          name="communication2"
          value="true"
          class="optin-check text-sm"
        >
          <span v-sanitize="t('form-communication-2-label')" />
        </InputCheck>
      </div>

      <div class="mb-6 flex items-center">
        <img
          src="../assets/images/picto-velo.png"
          width="45"
          height="42"
          :alt="t('b2c-picto-velo-alt')"
          class="mb-1 mr-2"
        >
        <InputCheck
          name="communication3"
          value="true"
          class="optin-check text-sm"
        >
          <span v-sanitize="t('form-communication-3-label')" />
        </InputCheck>
      </div>
    </template>

    <div class="text-center">
      <NCtaLoader
        data-cy="submit-register"
        :button-attributes="{ type: 'submit' }"
        class="mx-auto mb-2 md:max-w-[402px] md:text-lg"
        :state="registerLoadingState"
        @finished-animation="onApiResponse"
      >
        {{ token ? t("form-update") : t("form-submit") }}
      </NCtaLoader>
    </div>

    <p v-if="apiError" class="mt-2 text-center text-sm text-red-500">
      {{ apiError }}
    </p>
  </form>
</template>

<style scoped lang="scss">
* {
  --n-primary-color: #055754;
}

:deep(.optin-check label) {
  span {
    margin-top: -4.5px;
  }
}
</style>
