import { useI18n } from "vue-i18n";

export class ApiError<ResponseBody> extends Error {
  errorMessages: ResponseBody;

  constructor(message: string, errorMessages: ResponseBody) {
    super(message);
    this.errorMessages = errorMessages;
  }
}

/**
 * Parse API error return to Vee-Validate form errors message
 */
export function useApiErrorParser() {
  const { t } = useI18n();

  return <ErrorMessage extends { [key: string]: unknown }>(
    errorMessages: ErrorMessage,
  ) => {
    const finalMessage: { [key: string]: string } = {};

    if (errorMessages.last_name) {
      finalMessage.lastName = t("form-lastName-error-invalid");
    }

    if (errorMessages.first_name) {
      finalMessage.firstName = t("form-firstName-error-invalid");
    }

    if (errorMessages.birth_date) {
      finalMessage.birthdate = t("form-birthdate-error-invalid");
    }

    if (errorMessages.email) {
      finalMessage.email = t("form-email-error-invalid");
    }

    if (errorMessages.mobile_phone) {
      finalMessage.phone = t("form-phone-error-invalid");
    }

    if (errorMessages.rers) {
      finalMessage.railway = t("form-railway-error-empty");
    }

    if (errorMessages.transiliens) {
      finalMessage.railway = t("form-railway-error-empty");
    }

    if (errorMessages.departure_station) {
      finalMessage.startStation = t("form-station-error-invalid");
    }

    if (errorMessages.arrival_station) {
      finalMessage.endStation = t("form-station-error-invalid");
    }

    if (errorMessages.nearby_station) {
      finalMessage.station = t("form-station-error-invalid");
    }

    if (errorMessages.frequency) {
      finalMessage.frequency = t("form-frequency-error-empty");
    }

    if (errorMessages.optin) {
      finalMessage.communication = t("form-communication-error-empty");
    }

    if (errorMessages.optin_transilien_access_plus) {
      finalMessage.communication = t("form-communication-error-empty");
    }

    if (errorMessages.function) {
      finalMessage.function = t("api-error-400");
    }

    if (errorMessages.company_name) {
      finalMessage.companyName = t("form-companyName-error-empty");
    }

    if (errorMessages.company_size) {
      finalMessage.companySize = t("form-companySize-error-empty");
    }

    if (errorMessages.sector) {
      finalMessage.sector = t("api-error-400");
    }

    if (errorMessages.transport_ticket) {
      finalMessage.transportTicket = t("form-transportTicket-error-invalid");
    }

    return finalMessage;
  };
}
