<script setup lang="ts">
import InputAutocomplete from "@/components/InputAutocomplete.vue";
import InputCheck from "@/components/InputCheck.vue";
import InputRailway from "@/components/InputRailway.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputText from "@/components/InputText.vue";

import { useApi } from "@/composable/useApi";
import {
  useRerOptions,
  useStationOptions,
  useTransilienOptions,
  useTransportTicketOptions,
} from "@/composable/useApiList";
import { useToken } from "@/composable/useToken";
import { removeAccents } from "@/helpers/accents";
import { ApiError, useApiErrorParser } from "@/helpers/apiError";
import { getEmailRegex } from "@/helpers/email";
import { nameTest } from "@/helpers/name";
import { extractRailwaysFromList } from "@/helpers/railway";
import { type ExtractGeneric, RouteNames } from "@/types";
import { NCtaLoader, type ValidStateModel } from "@team-uep/n-cta-loader";
import { toTypedSchema } from "@vee-validate/yup";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";

const apiError = ref("");

const { t } = useI18n();
const route = useRoute();
const api = useApi();
const token = useToken();
const router = useRouter();
const { stationOptions, stationTest, getStationId, getStationName }
  = useStationOptions(apiError);
const { transportTicketOptions, transportTicketTest }
  = useTransportTicketOptions(apiError);
const { rerOptions } = useRerOptions(apiError);
const { transilienOptions } = useTransilienOptions(apiError);
const apiErrorParser = useApiErrorParser();
const displayOptin = ref(true);

function createValidationSchema() {
  const communication = yup
    .boolean()
    .oneOf([true], t("form-communication-error-empty-panel"))
    .required(t("form-communication-error-empty"));

  return yup.object({
    lastName: yup
      .string()
      .test("name", t("form-lastName-error-invalid"), nameTest)
      .required(t("form-lastName-error-empty")),
    firstName: yup
      .string()
      .test("name", t("form-firstName-error-invalid"), nameTest)
      .required(t("form-firstName-error-empty")),
    email: yup
      .string()
      .required(t("form-email-error-empty"))
      .matches(getEmailRegex(), t("form-email-error-invalid")),
    railway: yup.array().of(yup.string().required()),
    startStation: yup
      .string()
      .test("station", t("form-station-error-invalid"), stationTest),
    endStation: yup
      .string()
      .test("station", t("form-station-error-invalid"), stationTest),
    transportTicket: yup
      .string()
      .test(
        "station",
        t("form-transportTicket-error-invalid"),
        transportTicketTest,
      ),
    ...(displayOptin.value ? { communication } : {}),
  });
}

const { handleSubmit, setValues, setErrors } = useForm<
  ExtractGeneric<ReturnType<typeof createValidationSchema>>
>({
  validationSchema: computed(() =>
    toTypedSchema<any, any, any>(createValidationSchema()),
  ),
  initialValues: {
    email: route.query.email ? String(route.query.email) : undefined,
    railway: [],
  },
});

watch(
  token,
  async (tok?: string) => {
    if (tok) {
      try {
        const prefill = await api.getClientPanel(tok);

        // Disable optin if prefill otin is true
        if (prefill.optin) {
          displayOptin.value = false;
        }

        setValues({
          lastName: prefill.last_name,
          firstName: prefill.first_name,
          email: prefill.email,
          railway: [...(prefill.rers || []), ...(prefill.transiliens || [])],
          startStation: getStationName(prefill.departure_station),
          endStation: getStationName(prefill.arrival_station),
          transportTicket: prefill.transport_ticket,

          // optin is not required if prefill optin is true
          ...(prefill.optin
            ? { communication: undefined }
            : { communication: prefill.optin }),
        });
        displayOptin.value = !prefill.optin;

        // Remove optin error
        setErrors({ communication: undefined });
      }
      catch {
        router.push({ name: RouteNames.Panel });
      }
    }
  },
  { immediate: true },
);

const registerLoadingState = ref<ValidStateModel>("default");

const onSubmit = handleSubmit(async (values) => {
  registerLoadingState.value = "progress";

  apiError.value = "";
  try {
    await api.submitPanel({
      token: token.value,
      last_name: values.lastName,
      first_name: values.firstName,
      email: values.email,
      rers: extractRailwaysFromList(values.railway, rerOptions.value),
      transiliens: extractRailwaysFromList(
        values.railway,
        transilienOptions.value,
      ),
      departure_station: getStationId(values.startStation) || undefined,
      arrival_station: getStationId(values.endStation) || undefined,
      transport_ticket: values.transportTicket,

      // Send optin only if not prefilled with true value
      ...(values.communication === !!values.communication
        ? { optin: values.communication }
        : {}),
    });
    registerLoadingState.value = "success";
  }
  catch (error: unknown | Error) {
    registerLoadingState.value = "error";
    apiError.value
      = error instanceof Error ? error.message : JSON.stringify(error);

    if (error instanceof ApiError && error.errorMessages) {
      setErrors(apiErrorParser(error.errorMessages));
    }
  }
});

function onApiResponse(actualState: ValidStateModel) {
  if (actualState === "success") {
    return router.push({ name: RouteNames.PanelSuccess });
  }

  if (actualState === "error") {
    registerLoadingState.value = "default";
  }

  return undefined;
}
</script>

<template>
  <form @submit="onSubmit">
    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="lastName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-lastName-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputText
        name="firstName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-firstName-label')"
        :uppercase="true"
        class="flex-1"
      />
    </div>

    <InputText
      name="email"
      :input-attr="{ maxlength: '150' }"
      :label="t('form-email-label')"
      :uppercase="true"
      class="mb-6 md:w-1/2 md:pr-[1.375rem]"
    />

    <InputRailway
      :rer-options="rerOptions"
      :transilien-options="transilienOptions"
      name="railway"
      class="mb-6 max-w-lg"
    >
      {{ t("form-railway-title-panel") }}
    </InputRailway>

    <h2 class="current--title mb-2">
      {{ t("form-travel-label") }}
    </h2>

    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputAutocomplete
        name="startStation"
        :placeholder="t('form-startStation-placeholder')"
        :list="stationOptions"
        :label="t('form-startStation-label')"
        :transform="removeAccents"
        :not-found-message="t('form-station-not-found')"
        class="flex-1"
      />

      <InputAutocomplete
        name="endStation"
        :placeholder="t('form-endStation-placeholder')"
        :list="stationOptions"
        :label="t('form-endStation-label')"
        :transform="removeAccents"
        :not-found-message="t('form-station-not-found')"
        class="flex-1"
      />
    </div>

    <InputSelect
      name="transportTicket"
      :label="t('form-transportTicket-label')"
      :default-option="t('form-transportTicket-default')"
      :options="transportTicketOptions"
      :required="false"
      class="label--title mb-6 md:w-1/2 md:pr-[1.375rem]"
    />

    <template v-if="displayOptin">
      <div class="mb-6">
        <p v-sanitize="t('form-terms-panel')" class="text-sm" />
      </div>

      <InputCheck name="communication" value="true" class="mb-6 text-sm">
        <span v-sanitize="t('form-communication-label-panel')" />
      </InputCheck>
    </template>

    <div class="text-center">
      <NCtaLoader
        data-cy="submit-register"
        :button-attributes="{ type: 'submit' }"
        class="mx-auto mb-2 md:max-w-[402px] md:text-lg"
        :state="registerLoadingState"
        @finished-animation="onApiResponse"
      >
        {{ token ? t("form-update") : t("form-submit") }}
      </NCtaLoader>
    </div>

    <p v-if="apiError" class="mt-2 text-center text-sm text-red-500">
      {{ apiError }}
    </p>
  </form>
</template>

<style scoped lang="scss">
* {
  --n-primary-color: #055754;
}
</style>
