<script setup lang="ts">
import GreyLayout from "@/layouts/GreyLayout.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/banner-b2c.png"
      width="1024"
      height="178"
      :alt="t('b2c-img-alt')"
      class="mb-10 w-full"
    >

    <h1 class="mb-6 text-2xl text-[#4d4f53] md:text-center md:text-4xl">
      {{ t("b2c-success-title") }}
    </h1>

    <p v-sanitize="t('b2c-success-intro')" class="mb-6" />
    <p v-sanitize="t('b2c-success-message')" class="mb-6" />
    <p v-sanitize="t('b2c-success-link')" />
  </GreyLayout>
</template>
