export async function birthdayTest(value?: string) {
  const [year, month, day] = value?.split("-") || "";
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  const today = Date.now();
  const veryOld = 120 * 365 * 24 * 60 * 60 * 1000;
  return Promise.resolve(
    date.toLocaleDateString("en-GB").split("/").reverse().join("-") === value
    && date.getTime() > today - veryOld
    && date.getTime() < today,
  );
}

export function dateFrToIso(value?: string) {
  return value ? value.split("/").reverse().join("-") : "";
}

export function dateIsoToFr(value?: string) {
  return value ? value.split("-").reverse().join("/") : "";
}
