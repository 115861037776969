import type { App } from "vue";
import type { Router } from "vue-router";
import { isDevEnv, isLocalEnv, isStagingEnv } from "@/helpers/context";
import { initSentry, SentryEnvironment } from "@team-uep/vue-sentry";
import { name, version } from "../../package.json";

function getSentryEnv(): SentryEnvironment {
  if (isDevEnv() || isLocalEnv()) {
    return SentryEnvironment.Development;
  }

  if (isStagingEnv()) {
    return SentryEnvironment.Staging;
  }

  return SentryEnvironment.Production;
}

/**
 * Setup Sentry
 */
export function configureSentry({
  app,
  router,
  dsn,
}: {
  app: App<Element>;
  router: Router;
  dsn: string;
}) {
  initSentry({
    app,
    vueRouter: router,
    projectName: name,
    projectVersion: version,
    dsn,
    projectEnv: getSentryEnv(),
    // List of hostnames to block tracing.
    blockedTracingOrigins: [],
    // If true, enable the Replay feature.
    enableSentryReplay: true,
  });
}

export { initSentry };
