<script setup lang="ts">
import { useField, useIsFieldTouched } from "vee-validate";
import { type InputHTMLAttributes, watch } from "vue";

const props = defineProps<{
  name: string;
  inputAttr?: InputHTMLAttributes;
  value: string;
  disableErrors?: boolean;
}>();

const emit = defineEmits<{
  (e: "touched"): void;
}>();

const rand = Math.random().toString();

const {
  errors,
  value: fieldValue,
  errorMessage,
} = useField(props.name, undefined, {
  type: "checkbox",
});

const touched = useIsFieldTouched(props.name);

watch(touched, () => emit("touched"));
</script>

<template>
  <div>
    <p v-if="!disableErrors && errors.length" class="text-sm text-red-500">
      {{ errorMessage }}
    </p>
    <label :for="rand + value" class="flex cursor-pointer items-center gap-2">
      <input
        :id="rand + value"
        v-bind="inputAttr || {}"
        v-model="fieldValue"
        :name="name"
        type="checkbox"
        :value="value"
        class="cursor-pointer"
      >
      <span class="flex-1">
        <slot />
      </span>
    </label>
  </div>
</template>
