import { RouteNames } from "@/types";
import B2bFormView from "@/views/B2bFormView.vue";
import B2bSuccessView from "@/views/B2bSuccessView.vue";
import B2cFormView from "@/views/B2cFormView.vue";
import B2cSuccessView from "@/views/B2cSuccessView.vue";
import ErrorView from "@/views/ErrorView.vue";
import LandingVeloSuccessView from "@/views/LandingVeloSuccessView.vue";
import LandingVeloView from "@/views/LandingVeloView.vue";
import PanelFormView from "@/views/PanelFormView.vue";
import PanelSuccessView from "@/views/PanelSuccessView.vue";
import { createRouter, createWebHistory } from "vue-router";

export const routes = [
  {
    path: "/b2b",
    name: RouteNames.B2b,
    component: B2bFormView,
  },
  {
    path: "/b2b/success",
    name: RouteNames.B2bSuccess,
    component: B2bSuccessView,
  },
  {
    path: "/b2c",
    name: RouteNames.B2c,
    component: B2cFormView,
  },
  {
    path: "/b2c/success",
    name: RouteNames.B2cSuccess,
    component: B2cSuccessView,
  },
  {
    path: "/panel",
    name: RouteNames.Panel,
    component: PanelFormView,
  },
  {
    path: "/panel/success",
    name: RouteNames.PanelSuccess,
    component: PanelSuccessView,
  },
  {
    path: "/velo",
    name: RouteNames.Velo,
    component: LandingVeloView,
  },
  {
    path: "/velo/success",
    name: RouteNames.VeloSuccess,
    component: LandingVeloSuccessView,
  },
  {
    path: "/:catchAll(.*)",
    name: RouteNames.Error,
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
