import type { ObjectSchema } from "yup";
import type { paths } from "./openapi.interfaces";

// https://stackoverflow.com/questions/44851268/typescript-how-to-extract-the-generic-parameter-from-a-type
export type ExtractGeneric<T> = T extends ObjectSchema<infer U> ? U : T;

export enum RouteNames {
  B2b = "b2b",
  B2c = "b2c",
  Panel = "panel",
  B2bSuccess = "b2b-success",
  B2cSuccess = "b2c-success",
  PanelSuccess = "panel-success",
  Velo = "velo",
  VeloSuccess = "velo-success",
  Error = "error",
}

export type SelectInputOption = {
  label: string;
  value: string;
};

export type RailwayOption = {
  value: string;
  src: string;
  alt: string;
};

export type Frequencies =
  paths["/api/frequencies/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type PaginatedStationList =
  paths["/api/stations/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type CompanySizes =
  paths["/api/company-sizes/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type TransportTickets =
  paths["/api/transport-tickets/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type Rers =
  paths["/api/rers/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type Transiliens =
  paths["/api/transiliens/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type Stations =
  paths["/api/stations/"]["get"]["responses"]["200"]["content"]["application/json"]["results"];

export type ClientB2b =
  paths["/api/client/b2b"]["get"]["responses"]["200"]["content"]["application/json"];

export type ClientB2c =
  paths["/api/client/b2c"]["get"]["responses"]["200"]["content"]["application/json"];

export type ClientPanel =
  paths["/api/client/panel"]["get"]["responses"]["200"]["content"]["application/json"];

export type B2bPayload =
  paths["/api/clients/b2b"]["post"]["requestBody"]["content"]["application/json"];

export type B2cPayload =
  paths["/api/clients/b2c"]["post"]["requestBody"]["content"]["application/json"];

export type PanelPayload =
  paths["/api/clients/panel"]["post"]["requestBody"]["content"]["application/json"];

export type B2bReturn =
  paths["/api/clients/b2b"]["post"]["responses"]["200"]["content"]["application/json"];

export type B2cReturn =
  paths["/api/clients/b2c"]["post"]["responses"]["200"]["content"]["application/json"];

export type PanelReturn =
  paths["/api/clients/panel"]["post"]["responses"]["200"]["content"]["application/json"];
