/**
 * French phone detection
 */
export const getPhoneRegex = () => /^(0|\+33)[1-9]\d{8}$/;

export function frPhoneToStdPhone(value?: string) {
  return value && value[0] === "0" ? `+33${value.substr(1)}` : value || undefined;
}

export function stdPhoneToFrPhone(value?: string) {
  return value && value[0] === "+" ? value.replace("+33", "0") : undefined;
}
