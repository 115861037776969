import type {
  B2bPayload,
  B2bReturn,
  B2cPayload,
  B2cReturn,
  ClientB2b,
  ClientB2c,
  ClientPanel,
  CompanySizes,
  Frequencies,
  PanelPayload,
  PanelReturn,
  Rers,
  Stations,
  Transiliens,
  TransportTickets,
} from "@/types";
import { ApiError } from "@/helpers/apiError";
import { fetchRetry } from "@/helpers/fetchRetry";
import { formatRailwayFromAPI } from "@/helpers/railway";
import { formatSelectFromAPI } from "@/helpers/select";
import { useI18n } from "vue-i18n";

export function useApi() {
  const { t } = useI18n();

  /**
   * Catch and rename API errors
   */
  const catchApiError = async <Return>(callback: () => Promise<Return>) => {
    try {
      const response = await callback();
      return response;
    }
    catch {
      throw new Error(t("api-error-50X"));
    }
  };

  /**
   * Get JSON from API and parse errors
   */
  const getJsonFromResponse = async <ResponseBody>(
    response: Response,
  ) => {
    try {
      const results: ResponseBody = await response.json();

      if (response.status >= 300 || response.status < 200) {
        switch (response.status) {
          case 400:
            throw new ApiError(t("api-error-400"), results);
          case 404:
            throw new Error(t("api-error-404"));
          default:
            throw new Error(t("api-error-40X"));
        }
      }

      return results;
    }
    catch {
      throw new Error(t("api-error-50X"));
    }
  };

  return {
    async getFrequencies() {
      const response = await catchApiError(() =>
        fetchRetry("/api/frequencies/?format=json"),
      );
      const results = await getJsonFromResponse<Frequencies>(response);
      return (results || []).map(formatSelectFromAPI);
    },

    async getStations() {
      const response = await catchApiError(() =>
        fetchRetry("/api/stations/?format=json"),
      );
      const results = await getJsonFromResponse<Stations>(response);
      return (results || []).map(formatSelectFromAPI);
    },

    async getCompanySizes() {
      const response = await catchApiError(() =>
        fetchRetry("/api/company-sizes/?format=json"),
      );
      const results = await getJsonFromResponse<CompanySizes>(response);
      return (results || []).map(formatSelectFromAPI);
    },

    async getTransportTickets() {
      const response = await catchApiError(() =>
        fetchRetry("/api/transport-tickets/?format=json"),
      );
      const results = await getJsonFromResponse<TransportTickets>(response);
      return (results || []).map(formatSelectFromAPI);
    },

    async getRers() {
      const response = await catchApiError(() =>
        fetchRetry("/api/rers/?format=json"),
      );
      const results = await getJsonFromResponse<Rers>(response);
      return (results || []).map(formatRailwayFromAPI);
    },

    async getTransiliens() {
      const response = await catchApiError(() =>
        fetchRetry("/api/transiliens/?format=json"),
      );
      const results = await getJsonFromResponse<Transiliens>(response);
      return (results || []).map(formatRailwayFromAPI);
    },

    async getClientB2b(token: string) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/client/b2b?token=${encodeURIComponent(token)}`),
      );
      const results = await getJsonFromResponse<ClientB2b>(response);
      return results || {};
    },

    async getClientB2c(token: string) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/client/b2c?token=${encodeURIComponent(token)}`),
      );
      const results = await getJsonFromResponse<ClientB2c>(response);
      return results || {};
    },

    async getClientPanel(token: string) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/client/panel?token=${encodeURIComponent(token)}`),
      );
      const results = await getJsonFromResponse<ClientPanel>(response);
      return results || {};
    },

    async submitB2b(payload: B2bPayload) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/clients/b2b`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }),
      );
      const results = await getJsonFromResponse<B2bReturn>(response);
      return results;
    },

    async submitB2c(payload: B2cPayload) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/clients/b2c`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }),
      );
      const results = await getJsonFromResponse<B2cReturn>(response);
      return results;
    },

    async submitVelo(payload: B2cPayload) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/clients/velo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }),
      );
      const results = await getJsonFromResponse<B2cReturn>(response);
      return results;
    },

    async submitPanel(payload: PanelPayload) {
      const response = await catchApiError(() =>
        fetchRetry(`/api/clients/panel`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }),
      );
      const results = await getJsonFromResponse<PanelReturn>(response);
      return results;
    },
  };
}
